@use 'variables' as var;

%horizontal-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

%truncate-text {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%none-found {
  padding: var.$spacing-compact var.$spacing-sm;
  background-color: var.$focusBackground;
  border-radius: var.$radius-sm;
  letter-spacing: normal;
  font-size: 0.875rem;
}

%sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
